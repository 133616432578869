@import "../../styles/variables";
@import "../../styles/fonts";
.root {
    // position: relative;
    width: 100%;
    align-self: center;
    height: 100%;
}

.topContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 70px);
    padding: 20px;
}

.contentContainer {
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgContainer {
    width: 50%;
    padding: 20px;
}

.ctaBannerContainer {
    width: 100%;
    float: left;
    // position: absolute;
    // bottom: 0;
}

.mainBG {
    width: 100%;
}

.title {
    text-align: center;
    text-transform: $title-text-transform;
    margin-bottom: 20px;
    @include h1;
}

.description {
    text-align: center;
    text-transform: $description-text-transform;
    margin-bottom: 30px;
    @include h2;
}

.disclaimer {
    text-align: center;
    text-transform: $disclaimer-text-transform;
    @include paragraph;
}

.buttonsContainer {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto 30px auto;
}

.buttonWrapper {
    position: relative;
    display: table;
    margin: 15px auto;
}

.blocker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    width: 300px;
    // background: $button-color;
    background: #db0a5b;
    padding: 11px 21px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonImg {
    height: $button-icon-size;
}

.buttonText {
    margin: auto;
    text-align: center;
    display: block;
    @include paragraph;
    text-transform: $button-text-transform;
    // color: $button-text-color;
    color: #fff;
    font-weight: 600;
}

.uploadInput {
    display: none;
}

@media screen and (max-width: 767px) {
    .root {
        padding: 20px;
    }
    .buttonsContainer {
        width: auto;
        margin: 0 auto 40px auto;
    }
    .button {
        margin: 10px 0;
        align-items: center;
    }
    .contentContainer {
        width: 100%;
    }
    .bgContainer {
        display: none;
    }
    .ctaBannerContainer {
        display: none;
    }
    .topContainer {
        float: left;
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 980px) {
    .topContainer {
        height: calc(100vh - 165px);
    }
}