@import "fonts";
@import "variables";
@import "reset";
@import "semanticOverrides";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~semantic-ui-css/semantic.min.css";

body {
  color: $main-font-color;
  letter-spacing: 0.1em;
}

body ::-webkit-scrollbar-track {
  background: none;
}

.main-wrapper {
  width: 100%;
  height: 100%;
}

#root {
  font-family: "Roboto", Arial, sans-serif;
  overflow: hidden;
  padding-bottom: $footer-height;
  position: relative;
  display: flex;
  min-height: 100%;
  align-items: stretch;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

@media (max-width: 767px) {
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .main-wrapper {
    position: absolute;
    overflow: auto;
  }

  #root {
    padding-bottom: calc(#{$footer-height} * 0.5);
  }
}

// consent modal
.react-responsive-modal-container {
    font-family: "Roboto", Arial, sans-serif;
  }
  
  .react-responsive-modal-container .consent-text {
    margin     : 30px 15px 30px;
    line-height: 26px;
  }
  
  .react-responsive-modal-container .consent-button {
    margin: 0 auto 20px;
  }
  
  button.consent {
    border-radius  : 4px;
    border         : 1.5px solid #db0a5b;
    padding        : 10px 20px;
    font-size      : 16px;
    background-size: 300%;
    transition     : all 0.6s;
    margin         : 30px auto 0;
    display        : block;
    cursor         : pointer;
  }
  
  button.consent.green {
    color           : black;
    background-image: linear-gradient(90deg, white 50%, #db0a5b 50%);
  }
  
  button.consent:hover {
    color              : #fff;
    background-position: 100%;
  }
  
  button.consent:active {
    box-shadow: 0px 10px 20px -15px rgba(0, 0, 0, 0.75);
  }
  
  .consent-button a button.consent {
    margin: 0 auto;
  }