@import "../../styles/variables";
@import "../../styles/fonts";

.root {
  position: relative;
  width: 100%;
  margin: 0 auto;
  align-self: center;
}

.modelsTitle {
  text-align: center;
  margin-bottom: 40px;
  text-transform: $models-title-text-transform;
  @include h2;
}

.modelsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.modelContainer {
  margin: 3%;
  width: 19%;
}

.modelWrapper {
  display: flex;
  padding: 10px 0;
  width: 100%;
}

.model {
  width: 100%;
  margin: 0 5px 0 0;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
}

.modelImage {
  width: 100%;
}

.backBtn {
  margin: 0 auto;
  @include h2;
  display: table;
  padding: 20px;
  cursor: pointer;
  i {
    color: $back-button-cross-color;
  }
}

@media screen and (max-width: 767px) {
  .modelContainer {
    width: 100%;
    margin: 0 0 10px 0;
    min-width: auto;
  }

  .modelWrapper {
    flex-wrap: wrap;
  }

  .model {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
}

.bg_1 {
  width: 250px;
  height: 334px;
  background: url("../../static/img/models/models_sprites.png") -10px -10px;
}

.bg_2 {
  width: 250px;
  height: 328px;
  background: url("../../static/img/models/models_sprites.png") -550px -10px;
}

.bg_3 {
  width: 220px;
  height: 284px;
  background: url("../../static/img/models/models_sprites.png") -10px -364px;
}

.bg_4 {
  width: 250px;
  height: 330px;
  background: url("../../static/img/models/models_sprites.png") -280px -10px;
}
