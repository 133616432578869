@import "../../styles/variables";

.root {
  position: absolute;
  width: 100%;
  height: $footer-height;
  background: $main-bg-color;
  border-top: 3px solid $main-border-color;
  box-shadow: $main-box-shadow;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  align-self: flex-end;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  .root {
    height: calc(#{$footer-height} * 0.5);
  }
}
