@import "../../styles/variables";
.root {
  position: relative;
  border-radius: 4px;
}

.wrapper {
  box-shadow: $canvas-box-shadow;
  margin: 0 auto;
  display: table;
  position: relative;
}

.canvasWrapper {
  position: relative;
}

.outputCanvas,
.splitterCanvas {
  position: relative;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.splitterWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

.inputCanvas {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 100%;
}

.videoLive {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.inputCanvasContainer {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1;
  overflow: hidden;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

.splitter {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  display: none;

  &.active {
    display: block;
  }

  &:before {
    content: "";
    width: 4px;
    height: 100%;
    background: white;
    position: absolute;
    top: 0;
    right: calc(50% - 2px);
    z-index: 1;
  }

  &:after {
    content: "";
    height: 50px;
    width: 20px;
    position: absolute;
    top: 70%;
    right: calc(50% - 10px);
    z-index: 2;
    border-radius: 4px;
    background: center / contain no-repeat url("../../static/img/splitter.png"),
      white;
    cursor: grabbing;
  }
}

.closeButtonContainer {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}
.closeButton {
  border-radius: 4px;
  display: table;
  padding: 11px;
  cursor: pointer;
  color: $close-button-text-color;
  background: $close-button-background-color;
  border: 1px solid $close-button-border-color;
}

@media screen and (max-width: 767px) {
  .root {
    display: table;
    margin: 0 auto;
  }
}
