i.icon {
  margin: 0;
}

.slick-prev:before,
.slick-next:before {
  color: #e93b91;
}

.slick-prev {
  left: 10px;
  z-index: 999;
}

.slick-next {
  right: 10px;
  z-index: 999;
}
