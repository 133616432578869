@import "../../styles/variables";
.buttonContainer {
  border-top: 1px solid $canvas-buttons-border-color;
  padding: 14px;
  display: flex;
  justify-content: space-around;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.rangeWrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.rangeContainer {
  border-top: 1px solid $canvas-buttons-border-color;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 2rem;
  width: 100%;
  padding: 25px 0;
}

.button {
  border-radius: 4px;
  display: table;
  padding: 11px;
  cursor: pointer;
  color: $canvas-button-text-color;
  background: $canvas-button-background-color;
  border: 1px solid $canvas-button-border-color;

  &:hover {
    color: $canvas-button-text-hover-color;
    background: $canvas-button-background-hover-color;
    border: 1px solid $canvas-button-border-hover-color;
  }

  &.active {
    color: $canvas-button-background-color;
    background: $canvas-button-text-color;

    &:hover {
      color: $canvas-button-text-color;
      background: $canvas-button-background-color;
      border: 1px solid $canvas-button-border-color;
    }
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      color: $canvas-button-text-color;
      background: $canvas-button-background-color;
      border: 1px solid $canvas-button-border-color;
    }
  }
}

.hidden {
  display: none;
}

.activeTrack {
  height: 5px;
  background: $canvas-button-border-color;
}

.sliderContainer {
}

.slider {
  appearance: none;
  background: $canvas-button-background-hover-color;
  border: 1px solid $canvas-button-border-hover-color;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 2rem;
  margin-left: -0.5rem;
  margin-top: -1.1rem;
  outline: none;
  position: absolute;
  top: 50%;
  width: 2rem;
  &:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
}

.track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
}
