@import "./variables";
@font-face {
  font-family: "Roboto";
  src: url("../static/fonts/RobotoThin.eot");
  src: url("../static/fonts/RobotoThin.eot") format("embedded-opentype"),
    url("../static/fonts/RobotoThin.woff2") format("woff2"),
    url("../static/fonts/RobotoThin.woff") format("woff"),
    url("../static/fonts/RobotoThin.ttf") format("truetype"),
    url("../static/fonts/RobotoThin.svg#HelveticaNeueLt") format("svg");
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  src: url("../static/fonts/RobotoRegular.eot");
  src: url("../static/fonts/RobotoRegular.eot") format("embedded-opentype"),
    url("../static/fonts/RobotoRegular.woff2") format("woff2"),
    url("../static/fonts/RobotoRegular.woff") format("woff"),
    url("../static/fonts/RobotoRegular.ttf") format("truetype"),
    url("../static/fonts/RobotoRegular.svg#HelveticaNeueLt") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../static/fonts/RobotoMedium.eot");
  src: url("../static/fonts/RobotoMedium.eot") format("embedded-opentype"),
    url("../static/fonts/RobotoMedium.woff2") format("woff2"),
    url("../static/fonts/RobotoMedium.woff") format("woff"),
    url("../static/fonts/RobotoMedium.ttf") format("truetype"),
    url("../static/fonts/RobotoMedium.svg#HelveticaNeueLt") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "Roboto";
  src: url("../static/fonts/RobotoBold.eot");
  src: url("../static/fonts/RobotoBold.eot") format("embedded-opentype"),
    url("../static/fonts/RobotoBold.woff2") format("woff2"),
    url("../static/fonts/RobotoBold.woff") format("woff"),
    url("../static/fonts/RobotoBold.ttf") format("truetype"),
    url("../static/fonts/RobotoBold.svg#HelveticaNeueLt") format("svg");
  font-weight: 700;
}

@mixin h1 {
  font-family: $SecondaryFont;
  line-height: 1;
  font-size: $h1-font-size;
  @media screen and (max-width: 1069px) {
    font-size: calc(#{$h1-font-size} * 0.8);
  }
}

@mixin h2 {
  font-family: $MainFont;
  line-height: 1;
  font-size: $h2-font-size;
  @media screen and (max-width: 1069px) {
    font-size: calc(#{$h2-font-size} * 0.8);
  }
}

@mixin paragraph {
  font-family: $MainFont;
  line-height: 1.2;
  font-size: $paragraph-font-size;
  @media screen and (max-width: 1069px) {
    font-size: calc(#{$paragraph-font-size} * 0.8);
  }
}
