@import "../../styles/variables";
@import "../../styles/fonts";
.root {
  position: relative;
  width: 100%;
  height: $header-height;
  background: $header-bg-color;
  border-bottom: 3px solid $header-border-color;
  box-shadow: $header-box-shadow;
  display: flex;
}

.logoContainer {
  position: relative;
  width: $logo-width;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 26px;
}

.logo {
  position: relative;
  width: 100%;
}

.menu {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.mobileMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background: $header-bg-color;
  border-bottom: 3px solid $header-border-color;
  box-shadow: $header-box-shadow;
  border-radius: 4px;
  z-index: 10;
  max-height: calc(100vh - #{$header-height} * 0.8);

  .menuItem {
    border-top: 1px solid $header-border-color;
  }
}

.menuItem {
  color: $header-font-color;
  padding: 20px;
  @include paragraph;
  height: 100%;
  display: block;
}

.active,
.menuItem:hover {
  color: $header-hover-font-color;
}

.menuItemContent {
  max-width: 100%;
  max-height: 100%;
  display: table;
  padding: 34px 0;
}

i.icon {
  color: $header-font-color;
  font-size: calc(#{$header-height} * 0.8) !important;
  margin: auto 20px auto auto !important;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .root {
    height: calc(#{$header-height} * 0.8);
  }
  .logoContainer {
    width: calc(#{$logo-width} * 0.8);
  }
  i.icon {
    font-size: calc(#{$header-height} * 0.8 * 0.8) !important;
  }
  .mobileMenu {
    width: 100%;

    span {
      margin: 0 auto;
      display: table;
    }
  }
  .menuItemContent {
    padding: 17px 0;
  }
}
