@import "../../styles/variables";
.root {
  position: relative;
  width: 100%;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .root {
    min-height: calc(100vh - #{$header-height} * 0.5 - #{$footer-height} * 0.5);
  }
}
