@import "../../styles/variables";
@import "../../styles/fonts";

.root {
  position: relative;
  width: 100%;
}

.dropdownContainer {
  margin-bottom: 20px;
}

.paletteTitle {
  @include h2;
}

.makeUpContainer {
  border-radius: 4px;
  border: 1px solid $accordion-border-color;
  margin-bottom: 20px;
}
