$MainFont: "Roboto", Arial, sans-serif;
$SecondaryFont: "Roboto", Arial, sans-serif;

$main-font-color: #000;
$main-bg-color: #fff;
$main-border-color: #707070;
$main-box-shadow: none;
$back-button-cross-color: #dd4b39;

//Global typography variables
$h1-font-size: 49px;
$h2-font-size: 34px;
$paragraph-font-size: 22px;

//Header
$header-height: 67px;
$logo-width: 156px;
$header-bg-color: #303030;
$header-border-color: #fff;
$header-box-shadow: none;
$header-font-color: #fff;
$header-hover-font-color: #e4cbaf;

//Main screen content
$title-text-transform: none;
$description-text-transform: none;
$disclaimer-text-transform: none;
$button-text-transform: none;
$button-icon-size: 30px;
$button-color: rgb(224, 225, 226);
$button-text-color: #000;

//Footer
$footer-height: 1px;

//Models screen
$models-title-text-transform: uppercase;

//Presets screen
$presets-title-text-transform: uppercase;
$presets-item-text-transform: none;

//Canvas block
$canvas-box-shadow: 0 1px 6px 0 #d4d4d5;
$canvas-buttons-border-color: rgba(34, 36, 38, 0.1);
$canvas-button-background-color: #e0e1e2;
$canvas-button-text-color: #676768;
$canvas-button-border-color: #e0e1e2;
$canvas-button-background-hover-color: #cacbcd;
$canvas-button-text-hover-color: #676768;
$canvas-button-border-hover-color: #cacbcd;
$close-button-background-color: #dd4b39;
$close-button-text-color: #fff;
$close-button-border-color: #dd4b39;

//Accordions block
$accordion-border-color: rgba(34, 36, 38, 0.15);
$accordion-tab-background-color: #e0e1e2;
$accordion-tab-text-color: #676768;
$accordion-tab-background-active-color: #676768;
$accordion-tab-text-active-color: #e0e1e2;
