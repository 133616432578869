.typeContainer {
  width: 100%;
  position: relative;
  max-height: calc(100% - 80px);
  overflow: auto;
  border: 1px solid #e0e1e2;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.colorItem {
  width: 400px;
  height: 400px;
  cursor: pointer;
  margin: 10px;
  display: block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAALElEQVQYV2P8////fwYkwMjIKM2ILAgSAMnDBWECcEFkAbAgAwODFLKZIDYAiyYQiKtJnA0AAAAASUVORK5CYII=")
    repeat;
  border: 1px solid #e0e1e2;
  border-radius: 4px;
}

.modelItem {
  width: 100%;
  max-width: 400px;
  display: block;
  cursor: pointer;
  margin: 10px;
  border: 1px solid #e0e1e2;
  border-radius: 4px;

  &.active {
    border: 1px solid black;
  }
}
